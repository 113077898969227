import { TransFunction } from 'app';
import { showAppLoading, showAppModal } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import classNames from 'classnames';
import { EventEmitter } from 'events';
import { RouteViewProps } from 'lib';
import { AclContextProps, withAcl } from 'lib/decorators/acl';
import { buildSimpleTreeModel, imgproxy } from 'lib/helpers';
import {
  AclObjectList,
  InspectionTemplatePredefinedType,
  VehicleInspectionSite,
  VehicleInspectionTemplate,
} from 'model';
import {
  InspectionTemplateCategory,
  InspectionTemplateConf,
  InspectionTemplateGroup,
} from 'model/viewmodel/InspectionTemplateConf';
import qs from 'qs';
import React, {
  ChangeEvent,
  Component,
  FC,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
} from 'react';
import {
  getTranslate,
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { ThunkDispatch } from 'redux-thunk';
import {
  authenticate,
  Badge,
  BreadcrumbItem,
  CheckButton,
  Clear,
  CommonEntityListProps,
  getString,
  InlineSvg,
  Page,
  Restricted,
  Switch,
} from 'shared/components';
import { Block, Button } from 'shared/metronic/components';
import { arr2map, isNotNull, loadAsyncList } from 'utils';
import {
  createOrderComparerFromMap,
  findTargetGroupIdBySiteId,
  makeId,
} from '../common/helpers';
import {
  inspectionSiteActions,
  inspectionSiteCategoryActions,
  inspectionTemplateActions,
  templateDetailActions,
} from '../duck/actions';
import {
  InspectionSiteCategories,
  InspectionSites,
  InspectionTemplateDetail,
  InspectionTemplates,
} from '../duck/states';
import { InspectionSitePicker } from './site-picker';

import './detail.scss';

const sitePickerEvents = new EventEmitter();

const formatConditionalStringId = (
  s: string,
  hasRights: boolean,
  prefix = 'inspection_template.detail',
) => {
  s = prefix + '.' + s;
  if (!hasRights) s += '_readonly';
  return s;
};

interface Props
  extends CommonEntityListProps,
    LocalizeContextProps,
    RouteViewProps,
    AclContextProps {
  templateId: number;
  template: VehicleInspectionTemplate | null;
  templates: InspectionTemplates;
  categories: InspectionSiteCategories;
  sites: InspectionSites;
  detail: InspectionTemplateDetail;
}

function mapStateToProps(
  state: AppState,
  routeProps: RouteViewProps,
): Partial<Props> {
  const query = qs.parse(routeProps.location.search.substr(1));
  const templateId = Number(query.id);
  const template =
    state.inspection.templates.result?.find(x => x.id === templateId) || null;
  return {
    templateId,
    template,
    templates: state.inspection.templates,
    categories: state.inspection.categories,
    sites: state.inspection.sites,
    detail: state.inspection.templateDetail,
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

const GroupList: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="tpl-detail__group-list-sortable">{children}</div>;
};

const GroupListItem: FC<{
  groupId: string;
  selected: boolean;
  children?: ReactNode;
}> = ({ groupId, selected, children }) => {
  return (
    <div
      key={groupId}
      className={classNames('tpl-detail__group', {
        'tpl-detail__group--selected': selected,
      })}
    >
      {children}
    </div>
  );
};

const SortableGroupList = SortableContainer(GroupList);
const SortableGroupListItem = SortableElement(GroupListItem);

function TemplateSiteList({ children }: any) {
  return <div className="tpl-detail__site-list">{children}</div>;
}

function TemplateSiteListItem({ children, key }: any) {
  return (
    <div className="tpl-detail__site-list-item noselect" key={key}>
      {children}
    </div>
  );
}

const SortableTemplateSiteList = SortableContainer(TemplateSiteList);
const SortableTemplateSiteListItem = SortableElement(TemplateSiteListItem);
const SortableTemplateSiteListItemDragHandle = SortableHandle(() => (
  <InlineSvg src="img/icon-sort.svg" />
));

function SiteRelList({ children }: any) {
  return <span className="tpl-detail__site-rel-list">{children}</span>;
}

function SiteRelListItem({ children, key, readonly }: any) {
  return (
    <span
      className={classNames('tpl-detail__site-rel', {
        noselect: true,
        'tpl-detail__site-rel--disabled': readonly,
      })}
      key={key}
    >
      {children}
    </span>
  );
}

const SortableSiteRelList = SortableContainer(SiteRelList);
const SortableSiteRelListItem = SortableElement(SiteRelListItem);

@withAcl()
class InspectionTemplateDetailManagerImpl extends Component<Props> {
  panelHeight: number = 0;
  detailViewRef = React.createRef<HTMLDivElement>();
  isTemplateNotFoundAlerted = false;

  breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    {
      text: <Translate id="inspection.breadcrumb.templates_manager" />,
      href: '/inspection/templates/manager',
    },
  ];

  componentDidMount() {
    const { dispatch, template, templates, categories, sites } = this.props;
    loadAsyncList(templates, () => dispatch(inspectionTemplateActions.fetch()));
    loadAsyncList(categories, () =>
      dispatch(inspectionSiteCategoryActions.fetch()),
    );
    loadAsyncList(sites, () => dispatch(inspectionSiteActions.fetch()));

    this.calcPanelHeight();

    window.addEventListener('resize', this.onResize);

    if (this.props.templates.result && !this.props.template) {
      this.onTemplateNotFound();
      return;
    }

    if (template) {
      this.ready();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.templates.result && !this.props.template) {
      this.onTemplateNotFound();
      return;
    }
    if (!prevProps.template && this.props.template) {
      this.ready();
    }
    if (prevProps.detail.isSaving && !this.props.detail.isSaving) {
      const { dispatch } = this.props;
      const error = this.props.detail.saveError;
      error && console.error(error);
      const prefix = `inspection_template.detail.save_result.${
        error ? 'failure' : 'success'
      }`;
      dispatch(
        showAppLoading({
          message: `${prefix}.msg`,
          status: error ? 'error' : 'success',
          timeout: 3000,
        }),
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  render() {
    const { trans, template, templateId, templates } = this.props;
    const breadcrumbs = [...this.breadcrumbs];
    if (template) {
      const type =
        template.predefinedType || InspectionTemplatePredefinedType.Other;
      const typestr = getString(`inspection_template_predefined_type.${type}`);
      breadcrumbs.push({ text: `${typestr}→ ${template.name}` });
    }
    return (
      <Page
        title={trans('inspection_template.manager.title')}
        bodyStyle={{ padding: 0 }}
        breadcrumbs={breadcrumbs}
        fullAccessRight={AclObjectList.VehicleInspectionTemplateFullAccess}
        readonlyAccessRight={
          AclObjectList.VehicleInspectionTemplateReadonlyAccess
        }
        headerComplement={this.renderPageActions}
        error={templates.error}
        compact
        key={templateId}
      >
        {this.renderContent()}
      </Page>
    );
  }

  renderPageActions = () => {
    const { detail } = this.props;
    return (
      <Restricted
        rights={AclObjectList.VehicleInspectionTemplateFullAccess}
        silent
      >
        <Button
          color="brand"
          pill
          loader={detail.isSaving === true}
          disabled={detail.isSaving === true}
          onClick={this.onSave}
        >
          <Translate id="inspection_template.detail.btn.save" />
        </Button>
      </Restricted>
    );
  };

  renderContent() {
    const { detail, template, sites } = this.props;
    if (template && detail.templateId !== template.id) {
      return null;
    }
    return (
      <Block active={sites.isLoading}>
        <div
          className="tpl-detail"
          ref={this.detailViewRef}
          style={{
            minHeight: this.panelHeight,
          }}
        >
          {this.renderCategoryPanel()}
          {this.renderSitePanel()}
          {this.renderConfirmRemoveModal(
            'category',
            Boolean(detail.categoryIdBeingRemoved),
            this.onCancelRemoveCategory,
            this.onConfirmRemoveCategory,
          )}
          {this.renderConfirmRemoveModal(
            'group',
            Boolean(detail.groupIdBeingRemoved),
            this.onCancelRemoveGroup,
            this.onConfirmRemoveGroup,
          )}
          {this.renderConfirmRemoveModal(
            'site',
            Boolean(detail.siteIdsBeingDeleted?.length),
            this.onCancelSitesBeingRemoved,
            this.onConfirmSitesBeingRemoved,
          )}
          {this.renderSitePickerModel()}
        </div>
      </Block>
    );
  }

  renderPanel<T>(
    type: string,
    params: T,
    methods: {
      title: (params: T, hasRights: boolean) => ReactNode;
      headerRight?: (params: T, hasRights: boolean) => ReactNode;
      content: (params: T, hasRights: boolean) => ReactNode;
    },
  ) {
    return (
      <Restricted rights={AclObjectList.VehicleInspectionTemplateFullAccess}>
        {(_: any, hasRights: boolean) => (
          <div className={`tpl-detail__${type}-panel`}>
            <div className={`tpl-detail__${type}-panel-hd`}>
              <h3>{methods.title(params, hasRights)}</h3>
              {methods.headerRight && (
                <div className={`tpl-detail__${type}-panel-hd-right`}>
                  {methods.headerRight(params, hasRights)}
                </div>
              )}
            </div>
            <div className={`tpl-detail__${type}-panel-body`}>
              {methods.content(params, hasRights)}
            </div>
          </div>
        )}
      </Restricted>
    );
  }

  renderCategoryPanel() {
    const { template, detail } = this.props;
    const hasValue = (value: any) => value !== null && value !== undefined;
    return this.renderPanel('category', null, {
      title: (_: any, hasRights: boolean) => {
        return (
          <>
            <Translate id="inspection_template.detail.panel.category.title" />
            {hasRights && (
              <a href="#" onClick={this.onAddCategory}>
                <i className="la la-plus" />
              </a>
            )}
          </>
        );
      },
      content: (_: any, hasRights: boolean) => {
        if (
          !detail.conf?.categories.length &&
          !hasValue(detail.categoryNameBeingEdited)
        ) {
          return template ? (
            <div className="tpl-detail__category-panel-empty">
              <InlineSvg src="img/no-category2.svg" />
              <Translate
                id={formatConditionalStringId('no_categories', hasRights)}
                data={{
                  add_category_btn: (
                    <a href="#" onClick={this.onAddCategory}>
                      <Translate id="inspection_template.detail.btn.click_to_add_category" />
                    </a>
                  ),
                  add_all_system_categories_btn: (
                    <a href="#" onClick={this.onAddAllSystemCategories}>
                      <Translate id="inspection_template.detail.btn.add_all_system_categories" />
                    </a>
                  ),
                }}
              />
            </div>
          ) : (
            <div className="tpl-detail__category-panel-loading">
              <Translate id="inspection_template.detail.loading" />
            </div>
          );
        }

        const categoryCount = detail.conf!.categories.length;

        return (
          <div className="tpl-detail__category-list">
            {detail.conf!.categories.map((category, i) => {
              return (
                <div
                  key={category.id}
                  className={classNames('tpl-detail__category', {
                    'tpl-detail__category--expanded': category.expanded,
                  })}
                >
                  <div className="tpl-detail__category-hd">
                    <i
                      className={`fa fa-folder${
                        category.expanded ? '-open' : ''
                      }`}
                    />
                    {hasRights &&
                      detail.categoryIdWhoseNameIsBeingEdited ===
                        category.id && (
                        <input
                          type="text"
                          className="form-control form-control-sm m-input"
                          placeholder={getString(
                            'inspection_template.detail.placeholder.category_name',
                          )}
                          defaultValue={detail.categoryNameBeingEdited!}
                          onBlur={this.onCategoryNameBlur}
                          onKeyUp={this.onCategoryNameKeyup}
                          ref={c => {
                            c?.focus();
                            c?.select();
                          }}
                        />
                      )}
                    {detail.categoryIdWhoseNameIsBeingEdited !==
                      category.id && (
                      <span className="tpl-detail__category-name-ct">
                        <a
                          href="#"
                          className="tpl-detail__category-name"
                          onClick={this.onToggleCategory(category)}
                        >
                          {category.name}
                        </a>
                        {hasRights && (
                          <span className="tpl-detail__category-actions">
                            <a
                              href="#"
                              className="tpl-detail__category-name-edit"
                              onClick={this.onEditCategory(category)}
                            >
                              <i className="la la-pencil" />
                            </a>
                            {i > 0 && (
                              <a
                                href="#"
                                className="tpl-detail__category-move-up"
                                onClick={this.onMoveCategory(
                                  category.id,
                                  i,
                                  i - 1,
                                )}
                              >
                                <i className="la la-arrow-up" />
                              </a>
                            )}
                            {i < categoryCount - 1 && (
                              <a
                                href="#"
                                className="tpl-detail__category-move-down"
                                onClick={this.onMoveCategory(
                                  category.id,
                                  i,
                                  i + 1,
                                )}
                              >
                                <i className="la la-arrow-down" />
                              </a>
                            )}
                            <a
                              href="#"
                              className="tpl-detail__category-remove"
                              onClick={this.onRemoveCategory(category)}
                            >
                              <i className="la la-remove" />
                            </a>
                          </span>
                        )}
                      </span>
                    )}
                    <a href="#" onClick={this.onToggleCategory(category)}>
                      <i
                        className={`la la-angle-${
                          category.expanded ? 'up' : 'down'
                        }`}
                      />
                    </a>
                  </div>
                  <div className="tpl-detail__group-list">
                    <div
                      className={classNames('tpl-detail__group', {
                        'tpl-detail__group--selected':
                          detail.allGroupsSelectedCategoryId === category.id,
                      })}
                    >
                      <a
                        href="#"
                        data-category-id={category.id}
                        onClick={this.onAllGroupsClick}
                      >
                        <i
                          className="la la-remove tpl-detail__group-remove"
                          style={{ visibility: 'hidden' }}
                        />
                        <span className="tpl-detail__group-name">
                          <Translate id="inspection_template.detail.all_groups" />
                        </span>
                        <Badge
                          color="metal"
                          noRounded
                          className="tpl-detail__group-count"
                        >
                          {category.groups.reduce((res, g) => {
                            return res + g.siteIds.length;
                          }, 0)}
                        </Badge>
                      </a>
                    </div>
                    <SortableGroupList
                      helperClass="tpl-detail__group--being-dragged"
                      onSortEnd={e => this.onGroupSorted(category, e)}
                      lockAxis="y"
                      useWindowAsScrollContainer={true}
                      distance={1}
                    >
                      {category.groups.map((group, index) => (
                        <SortableGroupListItem
                          key={group.id}
                          index={index}
                          groupId={group.id}
                          selected={group.id === detail.selectedGroupId}
                        >
                          <a
                            href={
                              hasValue(detail.groupNameBeingEdited) &&
                              detail.groupIdWhoseNameIsBeingEdited === group.id
                                ? undefined
                                : ''
                            }
                            onClick={this.onGroupClick(group, hasRights)}
                          >
                            <i
                              className="la la-remove tpl-detail__group-remove"
                              onClick={this.onRemoveGroup(group)}
                              style={{
                                visibility: hasRights ? undefined : 'hidden',
                              }}
                            />
                            <span className="tpl-detail__group-name">
                              {hasValue(detail.groupNameBeingEdited) &&
                              detail.groupIdWhoseNameIsBeingEdited ===
                                group.id ? (
                                <input
                                  type="text"
                                  className="form-control form-control-sm m-input"
                                  placeholder={getString(
                                    'inspection_template.detail.placeholder.group_name',
                                  )}
                                  defaultValue={detail.groupNameBeingEdited!}
                                  onBlur={this.onGroupNameBlur}
                                  onKeyUp={this.onGroupNameKeyup}
                                  onMouseDown={this.onGroupNameMouseDown}
                                  onMouseMove={this.onGroupNameMouseMove}
                                  ref={c => c?.focus()}
                                />
                              ) : (
                                group.name
                              )}
                            </span>
                            <Badge
                              color="metal"
                              noRounded
                              className="tpl-detail__group-count"
                            >
                              {group.siteIds.length}
                            </Badge>
                          </a>
                        </SortableGroupListItem>
                      ))}
                    </SortableGroupList>
                    <div className="tpl-detail__group tpl-detail__group--new">
                      {hasValue(detail.groupNameBeingEdited) &&
                        !detail.groupIdWhoseNameIsBeingEdited &&
                        detail.categoryIdForNewGroup === category.id && (
                          <input
                            type="text"
                            className="form-control form-control-sm m-input"
                            placeholder={getString(
                              'inspection_template.detail.placeholder.group_name',
                            )}
                            defaultValue={detail.groupNameBeingEdited!}
                            onBlur={this.onGroupNameBlur}
                            onKeyUp={this.onGroupNameKeyup}
                            ref={c => c?.focus()}
                          />
                        )}
                      {!(
                        hasValue(detail.groupNameBeingEdited) &&
                        !detail.groupIdWhoseNameIsBeingEdited &&
                        detail.categoryIdForNewGroup === category.id
                      ) &&
                        hasRights && (
                          <a href="#" onClick={this.onAddGroup(category)}>
                            <span>
                              <i className="la la-plus" />
                              <Translate id="inspection_template.detail.btn.add_group" />
                            </span>
                          </a>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
            {hasValue(detail.categoryNameBeingEdited) &&
              !detail.categoryIdWhoseNameIsBeingEdited && (
                <div className="tpl-detail__category tpl-detail__category--editor">
                  <div className="tpl-detail__category-hd">
                    <i className="fa fa-folder" />
                    <input
                      type="text"
                      className="form-control form-control-sm m-input"
                      placeholder={getString(
                        'inspection_template.detail.placeholder.category_name',
                      )}
                      defaultValue={detail.categoryNameBeingEdited!}
                      onBlur={this.onCategoryNameBlur}
                      onKeyUp={this.onCategoryNameKeyup}
                      ref={c => c?.focus()}
                    />
                  </div>
                </div>
              )}
          </div>
        );
      },
    });
  }

  renderSitePanel() {
    const { template, detail } = this.props;
    let selectedGroup: InspectionTemplateGroup | undefined = undefined;
    if (detail?.selectedGroupId) {
      selectedGroup = this.getGroup(detail.selectedGroupId);
    }
    const category = detail.allGroupsSelectedCategoryId
      ? detail.conf!.categories.find(
          x => x.id === detail.allGroupsSelectedCategoryId,
        )
      : null;
    const keyword = selectedGroup ? selectedGroup.keyword : category?.keyword;
    const selection = selectedGroup
      ? selectedGroup.selection || []
      : category?.groups.reduce((res, g) => {
          return res.concat(g.selection || []);
        }, [] as number[]) || [];
    const siteCount = selectedGroup
      ? selectedGroup.siteIds.length
      : category?.groups.reduce((res, g) => {
          return res + g.siteIds.length;
        }, 0) || 0;
    return this.renderPanel(
      'site',
      { selectedGroup },
      {
        title: ({ selectedGroup: _ }, hasRights) => {
          return (
            <>
              <Translate id="inspection_template.detail.panel.site.title" />
              {hasRights && selectedGroup && (
                <a href="#" onClick={this.onShowSiteList}>
                  <i className="la la-plus" />
                </a>
              )}
              {(selectedGroup || detail.allGroupsSelectedCategoryId) && (
                <div
                  className="btn-group btn-group-sm"
                  style={{ marginLeft: '0.8rem' }}
                >
                  <button
                    type="button"
                    className={classNames('m-btn btn btn-secondary', {
                      active:
                        (selectedGroup &&
                          detail.siteListSortType === 'default') ||
                        (detail.allGroupsSelectedCategoryId &&
                          detail.allGroupsSiteListSortType === 'default'),
                    })}
                    data-sort-type="default"
                    onClick={this.onSiteListSortTypeChanged}
                  >
                    <Translate id="inspection_template.detail.btn.sort_type.default" />
                  </button>
                  <button
                    type="button"
                    className={classNames('m-btn btn btn-secondary', {
                      active:
                        (selectedGroup &&
                          detail.siteListSortType === 'workflow') ||
                        (detail.allGroupsSelectedCategoryId &&
                          detail.allGroupsSiteListSortType === 'workflow'),
                    })}
                    data-sort-type="workflow"
                    onClick={this.onSiteListSortTypeChanged}
                  >
                    <Translate id="inspection_template.detail.btn.sort_type.workflow" />
                  </button>
                </div>
              )}
            </>
          );
        },
        headerRight: ({ selectedGroup: _ }, hasRights) => {
          if (!selectedGroup && !detail.allGroupsSelectedCategoryId) {
            return null;
          }

          return (
            <>
              <div className="site-list__keyword-filter">
                <Clear
                  onClear={this.onClearSiteListKeyword}
                  visible={Boolean(keyword)}
                >
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    value={keyword || ''}
                    placeholder={getString(
                      'inspection_template.detail.modal.site_picker.keyword_placeholder',
                    )}
                    onChange={this.onSiteListKeywordChange}
                  />
                </Clear>
              </div>
              {hasRights && (
                <button
                  type="button"
                  className="btn btn-warning btn-sm m-btn m-btn--pill"
                  onClick={this.onRemoveSelectedSites}
                  disabled={Boolean(!selection.length)}
                >
                  <Translate id="remove_selected_btn_text" />
                </button>
              )}
              {hasRights && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm m-btn m-btn--pill"
                  onClick={this.onClearSites}
                  disabled={Boolean(!siteCount)}
                >
                  <Translate id="clear_all_btn_text" />
                </button>
              )}
            </>
          );
        },
        content: ({ selectedGroup: _ }, hasRights) => {
          if (!template) {
            return (
              <div className="tpl-detail__site-panel-loading">
                <Translate id="inspection_template.detail.loading" />
              </div>
            );
          }

          if (!selectedGroup && !detail.allGroupsSelectedCategoryId) {
            return (
              <div className="tpl-detail__site-panel-empty">
                <InlineSvg src="img/no-data.svg" />
                <Translate
                  id={formatConditionalStringId('no_group_selected', hasRights)}
                />
              </div>
            );
          }

          if (selectedGroup && !selectedGroup.siteIds.length) {
            return (
              <div className="tpl-detail__site-panel-empty">
                <InlineSvg src="img/no-data.svg" />
                <Translate
                  id={formatConditionalStringId('no_sites', hasRights)}
                  data={{
                    add_site_btn: (
                      <a href="#" onClick={this.onShowSiteList}>
                        <Translate id="inspection_template.detail.btn.click_to_add_site" />
                      </a>
                    ),
                  }}
                />
              </div>
            );
          }

          return this.renderSiteList(hasRights);
        },
      },
    );
  }

  renderSiteList(hasRights: boolean) {
    const readonly = !hasRights;

    const { sites, detail } = this.props;
    const currentGroup = this.getCurrentGroup();
    if (!currentGroup && !detail.allGroupsSelectedCategoryId) return null;

    const map = arr2map(sites.result!, x => x.id);

    // eslint-disable-next-line @typescript-eslint/init-declarations
    let disabledSiteIdSet: Set<number>;
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let selectedSiteIdSet: Set<number>;
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let defaultHiddenSiteIdSet: Set<number>;
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let requiredSiteIdSet: Set<number>;

    const addSiteRelsFromGroup = (group: InspectionTemplateGroup) => {
      if (group.siteRels) {
        for (const key of Object.keys(group.siteRels)) {
          const siteId = Number(key);
          const rels = group.siteRels[siteId].map(x => map[x]).filter(x => x);
          relsMap.set(siteId, rels);
        }
      }
    };

    const relsMap = new Map<number, VehicleInspectionSite[]>();
    if (currentGroup) {
      addSiteRelsFromGroup(currentGroup);
      disabledSiteIdSet = currentGroup.disabledSiteIds
        ? new Set(currentGroup.disabledSiteIds)
        : new Set();
      selectedSiteIdSet = new Set(currentGroup.selection || []);
      defaultHiddenSiteIdSet = currentGroup.defaultHiddenSiteIds
        ? new Set(currentGroup.defaultHiddenSiteIds)
        : new Set();
      requiredSiteIdSet = currentGroup.requiredSiteIds
        ? new Set(currentGroup.requiredSiteIds)
        : new Set();
    } else {
      disabledSiteIdSet = new Set();
      selectedSiteIdSet = new Set();
      defaultHiddenSiteIdSet = new Set();
      requiredSiteIdSet = new Set();
      const category = detail.conf!.categories.find(
        x => x.id === detail.allGroupsSelectedCategoryId!,
      )!;
      for (const group of category.groups) {
        addSiteRelsFromGroup(group);
        if (group.disabledSiteIds) {
          for (const siteId of group.disabledSiteIds) {
            disabledSiteIdSet.add(siteId);
          }
        }
        if (group.selection) {
          for (const siteId of group.selection) {
            selectedSiteIdSet.add(siteId);
          }
        }
        if (group.defaultHiddenSiteIds) {
          for (const siteId of group.defaultHiddenSiteIds) {
            defaultHiddenSiteIdSet.add(siteId);
          }
        }
        if (group.requiredSiteIds) {
          for (const siteId of group.requiredSiteIds) {
            requiredSiteIdSet.add(siteId);
          }
        }
      }
    }

    const list = this.buildSiteList();

    const isAllSelected = list.every(x => selectedSiteIdSet.has(x.id));

    return (
      <SortableTemplateSiteList
        helperClass="tpl-detail__site-list-item--being-dragged"
        onSortEnd={this.onSiteListSorted}
        useDragHandle
        lock
        lockAxis="y"
        useWindowAsScrollContainer={true}
      >
        <div className="tpl-detail__site-list-hd">
          {!readonly &&
            this.renderCheckCol(
              isAllSelected,
              null,
              this.onToggleSelectAllSites,
            )}
          {!readonly && (
            <div className="tpl-detail__site-col-sort">
              <InlineSvg src="img/icon-sort-2.svg" />
            </div>
          )}
          <div className="tpl-detail__site-col-icon">
            <Translate id="inspection_template.detail.site_list.col.icon" />
          </div>
          <div className="tpl-detail__site-col-name">
            <Translate id="inspection_template.detail.site_list.col.name" />
          </div>
          <div className="tpl-detail__site-col-rels">
            <Translate id="inspection_template.detail.site_list.col.rels" />
          </div>
          {!readonly && (
            <div className="tpl-detail__site-col-default-hidden">
              <Translate id="inspection_template.detail.site_list.col.default_hidden" />
            </div>
          )}
          {!readonly && (
            <div className="tpl-detail__site-col-required">
              <Translate id="inspection_template.detail.site_list.col.required" />
            </div>
          )}
          {!readonly && (
            <div className="tpl-detail__site-col-actions">
              <Translate id="col.actions" />
            </div>
          )}
        </div>
        {list.map((site, index) => (
          <SortableTemplateSiteListItem
            index={index}
            key={site.id}
            disabled={readonly}
          >
            {!readonly &&
              this.renderCheckCol(
                selectedSiteIdSet.has(site.id),
                site.id,
                this.onSiteCheckChange,
              )}
            {!readonly && (
              <div className="tpl-detail__site-col-sort">
                <SortableTemplateSiteListItemDragHandle />
              </div>
            )}
            <div className="tpl-detail__site-col-icon">
              {(() => {
                if (!site.iconUrl) {
                  return (
                    <InlineSvg
                      className="inspection-site-icon"
                      src="img/default-site-icon.svg"
                    />
                  );
                }
                const url = new URL(site.iconUrl);
                if (/\.svg$/i.exec(url.pathname)) {
                  return (
                    <InlineSvg
                      className="inspection-site-icon"
                      src={imgproxy(site.iconUrl)}
                    />
                  );
                }
                return (
                  <span className="inspection-site-icon">
                    <img src={site.iconUrl} />
                  </span>
                );
              })()}
            </div>
            <div className="tpl-detail__site-col-name">
              <span
                style={{
                  verticalAlign: 'middle',
                  display: 'inline-block',
                }}
              >{`${site.name}`}</span>
              <span
                style={{
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  border: '1px solid #ddd',
                  marginLeft: '0.5rem',
                  padding: '0.15rem 0.5rem',
                  borderRadius: '0.25rem',
                  backgroundColor: '#eee',
                  fontSize: '0.9rem',
                  lineHeight: '1rem',
                }}
              >{`${site.code}`}</span>
            </div>
            <div className="tpl-detail__site-col-rels">
              {(() => {
                const rels = relsMap.get(site.id) || [];
                if (!rels?.length) {
                  return (
                    <span className="tpl-detail__site-no-rels">
                      <i className="la la-info-circle" />
                      <Translate
                        id={formatConditionalStringId(
                          'site_list.no_rels',
                          hasRights,
                        )}
                        data={{
                          add_btn: (
                            <a
                              href="#"
                              onClick={this.onEditSiteRels}
                              data-site-id={site.id}
                            >
                              <Translate id="inspection_template.detail.btn.click_to_add_site_rel" />
                            </a>
                          ),
                        }}
                      />
                    </span>
                  );
                }
                return (
                  <SortableSiteRelList
                    helperClass="tpl-detail__site-rel--being-dragged"
                    onSortEnd={this.onSiteRelListSorted}
                    axis="x"
                    lock
                    lockAxis="x"
                    distance={1}
                  >
                    {rels.map((rel, idx) => (
                      <SortableSiteRelListItem
                        index={idx}
                        key={rel.id}
                        collection={site.id}
                        disabled={!hasRights}
                        readonly={!hasRights}
                      >
                        <span className="tpl-detail__site-rel-name">
                          {rel.name}
                        </span>
                        {hasRights && (
                          <span
                            className="tpl-detail__site-rel-remove"
                            data-site-id={site.id}
                            data-rel-id={rel.id}
                            onClick={this.onRemoteSiteRel}
                          >
                            <i className="la la-remove" />
                          </span>
                        )}
                      </SortableSiteRelListItem>
                    ))}
                    {hasRights && (
                      <a
                        href="#"
                        className="tpl-detail__site-rels-edit"
                        data-site-id={site.id}
                        onClick={this.onEditSiteRels}
                      >
                        <i className="la la-pencil-square" />
                      </a>
                    )}
                  </SortableSiteRelList>
                );
              })()}
            </div>
            {hasRights && (
              <div className="tpl-detail__site-col-default-hidden">
                <CheckButton
                  label=""
                  checked={defaultHiddenSiteIdSet.has(site.id)}
                  onChange={this.onSiteDefaultHiddenStatusChanged(site.id)}
                />
              </div>
            )}
            {hasRights && (
              <div className="tpl-detail__site-col-required">
                <CheckButton
                  label=""
                  checked={requiredSiteIdSet.has(site.id)}
                  onChange={this.onSiteRequiredStatusChanged(site.id)}
                />
              </div>
            )}
            {hasRights && (
              <div className="tpl-detail__site-col-actions">
                <Switch
                  size="small"
                  color="brand"
                  on={!disabledSiteIdSet.has(site.id)}
                  onChange={this.onSiteEnableStatusChanged(site.id)}
                />
                <a href="#" onClick={this.onRemoveSite(site.id)}>
                  <i className="la la-trash-o" />
                </a>
              </div>
            )}
          </SortableTemplateSiteListItem>
        ))}
      </SortableTemplateSiteList>
    );
  }

  renderConfirmRemoveModal(
    type: 'category' | 'group' | 'site',
    isOpen: boolean,
    onCancel: () => void,
    onConfirm: () => void,
  ) {
    const localeSegment = 'inspection_template.detail';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          <Translate id={`${localeSegment}.modal.remove_${type}.title`} />
        </ModalHeader>
        <ModalBody>
          <ReactMarkdown>
            {getString(`${localeSegment}.modal.remove_${type}.msg`)}
          </ReactMarkdown>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="primary" onClick={onConfirm}>
            <Translate id="yes_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderSitePickerModel() {
    const { trans, categories, sites, detail } = this.props;
    const prefix = 'inspection_template.detail.modal.site_picker';
    const prefix2 = 'inspection_template.detail.modal.site_rel_picker';
    const isEditRels = Boolean(detail.siteIdWhoseRelsAreBeingEdited);
    let selectedSiteIds: number[] = [];
    let excludedSiteId: number | undefined = undefined;
    if (detail.siteIdWhoseRelsAreBeingEdited) {
      const groupId = findTargetGroupIdBySiteId(
        detail,
        detail.siteIdWhoseRelsAreBeingEdited,
      )!;
      const group = this.getGroup(groupId)!;
      const siteId = detail.siteIdWhoseRelsAreBeingEdited;
      selectedSiteIds = group.siteRels?.[siteId] || [];
      excludedSiteId = siteId;
    } else {
      const group = this.getCurrentGroup();
      if (group) {
        selectedSiteIds = group.siteIds;
      }
    }
    return (
      <Modal
        isOpen={detail.showSiteList || isEditRels}
        size="lg"
        onClosed={this.onSitePickerClose}
      >
        <ModalHeader toggle={this.onSitePickerClose}>
          <Translate id={`${isEditRels ? prefix2 : prefix}.title`} />
          <span className="site-picker__actions">
            <a href="#" onClick={this.onSitePickerSelectAllSites}>
              <Translate id="inspection_template.detail.modal.site_picker.select_all" />
            </a>
            <a href="#" onClick={this.onSitePickerDeselectAllSites}>
              <Translate id="inspection_template.detail.modal.site_picker.deselect_all" />
            </a>
          </span>
        </ModalHeader>
        <ModalBody>
          <InspectionSitePicker
            excludedSiteId={excludedSiteId}
            trans={trans}
            categories={categories}
            sites={sites}
            selectedSiteIds={selectedSiteIds}
            event={sitePickerEvents}
            onSiteSelectionChange={this.onSitePickerSelectionChange}
          />
        </ModalBody>
      </Modal>
    );
  }

  renderCheckCol(
    checked: boolean,
    value: number | null | undefined,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean,
  ) {
    const cls = classNames([
      'm-checkbox',
      'm-checkbox--single',
      'm-checkbox--all',
      'm-checkbox--solid',
      'm-checkbox--brand',
    ]);
    return (
      <div className="tpl-detail__site-col-check">
        <label className={cls}>
          <input
            type="checkbox"
            value={value || 0}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
          />
          &nbsp;
          <span />
        </label>
      </div>
    );
  }

  onTemplateNotFound() {
    if (this.isTemplateNotFoundAlerted) return;
    const { dispatch, history } = this.props;
    this.isTemplateNotFoundAlerted = true;
    dispatch(
      showAppModal(
        '@string/oops',
        'inspection_template.detail.template_not_found',
        () => {
          history.replace('/inspection/templates/manager');
        },
      ),
    );
  }

  onAddCategory = (e: MouseEvent) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(templateDetailActions.addCategory());
  };

  onEditCategory(category: InspectionTemplateCategory) {
    return (e: MouseEvent) => {
      e.preventDefault();
      const { dispatch } = this.props;
      dispatch(templateDetailActions.editCategory(category.id));
    };
  }

  onCategoryNameKeyup = (e: KeyboardEvent) => {
    const { dispatch } = this.props;
    if (e.key === 'Escape') {
      // cancel
      dispatch(templateDetailActions.editCategoryCancelled());
    } else if (e.key === 'Enter') {
      // enter
      const el = e.target as HTMLInputElement;
      const name = el.value.trim();
      if (name.length) {
        dispatch(templateDetailActions.editCategoryCommitted(name));
      }
    }
  };

  onCategoryNameBlur = (e: FocusEvent) => {
    const { dispatch } = this.props;
    const el = e.target as HTMLInputElement;
    const name = el.value.trim();
    if (name.length) {
      dispatch(templateDetailActions.editCategoryCommitted(name));
    } else {
      dispatch(templateDetailActions.editCategoryCancelled());
    }
  };

  onAddAllSystemCategories = (e: MouseEvent) => {
    e.preventDefault();
    const { categories, dispatch } = this.props;
    if (!categories.result) return;
    const nodes = buildSimpleTreeModel(
      categories.result,
      x => x.id,
      x => x.parentCategoryId,
    );
    const conf: InspectionTemplateConf = { categories: [] };
    for (const node of nodes) {
      const category: InspectionTemplateCategory = {
        id: makeId(),
        name: node.data.name,
        groups: [],
        expanded: true,
      };
      conf.categories.push(category);
      if (node.children) {
        for (const childNode of node.children) {
          const group: InspectionTemplateGroup = {
            id: makeId(),
            categoryId: category.id,
            name: childNode.data.name,
            siteIds: [],
          };
          category.groups.push(group);
        }
      }
    }
    dispatch(templateDetailActions.update(conf));
  };

  onToggleCategory(category: InspectionTemplateCategory) {
    return (e: MouseEvent) => {
      e.preventDefault();
      const { dispatch } = this.props;
      if (category.expanded) {
        dispatch(templateDetailActions.collapseCategory(category.id));
      } else {
        dispatch(templateDetailActions.expandCategory(category.id));
      }
    };
  }

  onMoveCategory(categoryId: string, from: number, to: number) {
    return (e: MouseEvent) => {
      e.preventDefault();
      const { dispatch } = this.props;
      dispatch(templateDetailActions.categoryMoved(categoryId, from, to));
    };
  }

  onRemoveCategory(category: InspectionTemplateCategory) {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const { dispatch } = this.props;
      dispatch(templateDetailActions.removeCategory(category.id));
    };
  }

  onConfirmRemoveCategory = () => {
    const { dispatch } = this.props;
    dispatch(templateDetailActions.commitRemoveCategory());
  };

  onCancelRemoveCategory = () => {
    const { dispatch } = this.props;
    dispatch(templateDetailActions.cancelRemoveCategory());
  };

  onAddGroup(category: InspectionTemplateCategory) {
    return (e: MouseEvent) => {
      e.preventDefault();
      const { dispatch } = this.props;
      dispatch(templateDetailActions.addGroup(category.id));
    };
  }

  onGroupNameKeyup = (e: KeyboardEvent) => {
    const { dispatch } = this.props;
    if (e.key === 'Escape') {
      // cancel
      dispatch(templateDetailActions.editGroupCancelled());
    } else if (e.key === 'Enter') {
      // enter
      const el = e.target as HTMLInputElement;
      const name = el.value.trim();
      if (name.length) {
        dispatch(templateDetailActions.editGroupCommitted(name));
      }
    }
  };

  onGroupNameMouseDown = (e: MouseEvent) => {
    e.stopPropagation();
  };

  onGroupNameMouseMove = (e: MouseEvent) => {
    e.stopPropagation();
  };

  onGroupNameBlur = (e: FocusEvent) => {
    const { dispatch } = this.props;
    const el = e.target as HTMLInputElement;
    const name = el.value.trim();
    if (name.length) {
      dispatch(templateDetailActions.editGroupCommitted(name));
    } else {
      dispatch(templateDetailActions.editGroupCancelled());
    }
  };

  onGroupClick(group: InspectionTemplateGroup, hasRights: boolean) {
    return (e: MouseEvent) => {
      e.preventDefault();
      const { dispatch, detail } = this.props;
      if (detail.selectedGroupId !== group.id) {
        dispatch(templateDetailActions.groupSelected(group.id));
      } else if (
        hasRights &&
        detail.groupIdWhoseNameIsBeingEdited !== group.id
      ) {
        dispatch(templateDetailActions.editGroup(group.id, group.name));
      }
    };
  }

  onAllGroupsClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const categoryId = e.currentTarget.getAttribute('data-category-id')!;
    const { dispatch } = this.props;
    dispatch(templateDetailActions.allGroupsSelected(categoryId));
  };

  onGroupSorted = (
    category: InspectionTemplateCategory,
    e: { oldIndex: number; newIndex: number },
  ) => {
    const { dispatch } = this.props;
    const { newIndex, oldIndex } = e;
    const group = category.groups[oldIndex];
    if (newIndex === oldIndex) return;
    dispatch(templateDetailActions.groupMoved(group.id, oldIndex, newIndex));
  };

  onRemoveGroup(group: InspectionTemplateGroup) {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const { dispatch } = this.props;
      dispatch(templateDetailActions.removeGroup(group.id));
    };
  }

  onConfirmRemoveGroup = () => {
    const { dispatch } = this.props;
    dispatch(templateDetailActions.commitRemoveGroup());
  };

  onCancelRemoveGroup = () => {
    const { dispatch } = this.props;
    dispatch(templateDetailActions.cancelRemoveGroup());
  };

  onShowSiteList = (e: MouseEvent) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(templateDetailActions.showSiteList());
  };

  onSiteListSortTypeChanged = (e: MouseEvent) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const el = e.target as HTMLElement;
    const sortType = el.getAttribute('data-sort-type') as any;
    dispatch(templateDetailActions.siteListSortTypeChanged(sortType));
  };

  onSitePickerClose = () => {
    const { dispatch, detail } = this.props;
    if (detail.siteIdWhoseRelsAreBeingEdited) {
      const siteId = detail.siteIdWhoseRelsAreBeingEdited!;
      dispatch(templateDetailActions.endEditSiteRels(siteId));
    } else {
      dispatch(templateDetailActions.hideSiteList());
    }
  };

  onSitePickerSelectionChange = (siteIds: number[], selected: boolean) => {
    const { dispatch, detail } = this.props;
    if (detail.siteIdWhoseRelsAreBeingEdited) {
      const groupId = findTargetGroupIdBySiteId(
        detail,
        detail.siteIdWhoseRelsAreBeingEdited,
      );
      if (!groupId) return;
      const siteId = detail.siteIdWhoseRelsAreBeingEdited;
      const group = this.getGroup(groupId)!;
      let rels = group.siteRels?.[siteId] || [];
      const set = new Set(siteIds);
      if (selected) rels = [...rels, ...siteIds];
      else rels = rels.filter(x => !set.has(x));
      dispatch(templateDetailActions.siteRelsChanged(siteId, rels));
    } else {
      const groupId = detail.selectedGroupId!;
      if (selected) {
        dispatch(templateDetailActions.addSites(groupId, siteIds));
      } else {
        dispatch(templateDetailActions.removeSites(groupId, siteIds));
      }
    }
  };

  onSitePickerSelectAllSites = (e: MouseEvent) => {
    e.preventDefault();
    sitePickerEvents.emit('select-all-sites');
  };

  onSitePickerDeselectAllSites = (e: MouseEvent) => {
    e.preventDefault();
    sitePickerEvents.emit('deselect-all-sites');
  };

  onSiteListSorted = (e: { oldIndex: number; newIndex: number }) => {
    const { dispatch, detail } = this.props;
    const { newIndex, oldIndex } = e;
    if (newIndex === oldIndex) return;
    dispatch(
      templateDetailActions.siteMoved(
        detail.selectedGroupId!,
        oldIndex,
        newIndex,
      ),
    );
  };

  onRemoteSiteRel = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const siteId = Number(e.currentTarget.getAttribute('data-site-id'));
    const relId = Number(e.currentTarget.getAttribute('data-rel-id'));
    const { dispatch } = this.props;
    dispatch(templateDetailActions.removeSiteRels(siteId, [relId]));
  };

  onSiteRelListSorted = (args: {
    collection: number;
    oldIndex: number;
    newIndex: number;
  }) => {
    const { dispatch } = this.props;
    const { newIndex, oldIndex, collection } = args;
    if (newIndex === oldIndex) return;
    const siteId = collection;
    dispatch(templateDetailActions.siteRelMoved(siteId, oldIndex, newIndex));
  };

  onClearSiteListKeyword = () => {
    const { dispatch } = this.props;
    dispatch(templateDetailActions.siteListKeywordChanged(''));
  };

  onSiteListKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { dispatch } = this.props;
    dispatch(templateDetailActions.siteListKeywordChanged(e.target.value));
  };

  onSiteEnableStatusChanged(siteId: number) {
    return (enabled: boolean) => {
      const { dispatch, detail } = this.props;
      const groupId = findTargetGroupIdBySiteId(detail, siteId);
      if (!groupId) return;
      if (enabled) {
        dispatch(templateDetailActions.enableSite(groupId, siteId));
      } else {
        dispatch(templateDetailActions.disableSite(groupId, siteId));
      }
    };
  }

  onSiteDefaultHiddenStatusChanged(siteId: number) {
    return (_: any, isDefaultHidden: boolean) => {
      const { dispatch, detail } = this.props;
      const groupId = findTargetGroupIdBySiteId(detail, siteId);
      if (!groupId) return;
      dispatch(
        templateDetailActions.setSiteDefaultHidden(
          groupId,
          siteId,
          isDefaultHidden,
        ),
      );
    };
  }

  onSiteRequiredStatusChanged(siteId: number) {
    return (_: any, required: boolean) => {
      const { dispatch, detail } = this.props;
      const groupId = findTargetGroupIdBySiteId(detail, siteId);
      if (!groupId) return;
      dispatch(
        templateDetailActions.setSiteAsRequired(groupId, siteId, required),
      );
    };
  }

  onRemoveSite(siteId: number) {
    return (e: MouseEvent) => {
      e.preventDefault();
      const { dispatch, detail } = this.props;
      const groupId = findTargetGroupIdBySiteId(detail, siteId);
      if (!groupId) return;
      dispatch(templateDetailActions.removeSite(groupId, siteId));
    };
  }

  onRemoveSelectedSites = (e: MouseEvent) => {
    e.preventDefault();
    const { dispatch, detail } = this.props;
    const list = this.buildSiteList();
    const set = new Set(list.map(x => x.id));
    const currentGroup = this.getCurrentGroup()!;

    const removeGroupSelection = (
      g: InspectionTemplateGroup,
      confirm: boolean,
    ) => {
      const selection = g.selection || [];
      const siteIds = selection.filter(x => set.has(x));
      dispatch(templateDetailActions.removeSites(g.id, siteIds, confirm));
    };

    if (currentGroup) {
      removeGroupSelection(currentGroup, true);
    } else if (detail.allGroupsSelectedCategoryId) {
      const category = detail.conf!.categories.find(
        x => x.id === detail.allGroupsSelectedCategoryId,
      )!;
      const msg = getString('inspection_template.detail.modal.remove_site.msg');
      if (!confirm(msg)) return;
      for (const group of category.groups) {
        removeGroupSelection(group, false);
      }
    }
  };

  onClearSites = (e: MouseEvent) => {
    e.preventDefault();
    const { dispatch, detail } = this.props;
    const currentGroup = this.getCurrentGroup()!;
    if (currentGroup) {
      const siteIds = currentGroup.siteIds.slice();
      dispatch(
        templateDetailActions.removeSites(currentGroup.id, siteIds, true),
      );
    } else if (detail.allGroupsSelectedCategoryId) {
      const category = detail.conf!.categories.find(
        x => x.id === detail.allGroupsSelectedCategoryId,
      )!;
      const msg = getString('inspection_template.detail.modal.remove_site.msg');
      if (!confirm(msg)) return;
      for (const group of category.groups) {
        dispatch(
          templateDetailActions.removeSites(group.id, group.siteIds, false),
        );
      }
    }
  };

  onConfirmSitesBeingRemoved = () => {
    const { dispatch } = this.props;
    dispatch(templateDetailActions.commitSitesBeingRemoved());
  };

  onCancelSitesBeingRemoved = () => {
    const { dispatch } = this.props;
    dispatch(templateDetailActions.cancelSitesBeingRemoved());
  };

  onToggleSelectAllSites = () => {
    const { dispatch } = this.props;
    dispatch(templateDetailActions.toggleSelectAllSites());
  };

  onSiteCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { dispatch } = this.props;
    const siteId = Number(e.target.value);
    if (e.target.checked) {
      dispatch(templateDetailActions.siteSelected(siteId));
    } else {
      dispatch(templateDetailActions.siteDeselected(siteId));
    }
  };

  onEditSiteRels = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const siteId = Number(e.currentTarget.getAttribute('data-site-id'));
    dispatch(templateDetailActions.editSiteRels(siteId));
  };

  onResize = () => {
    this.calcPanelHeight();
    if (this.detailViewRef.current) {
      this.detailViewRef.current.style.minHeight = `${this.panelHeight}px`;
    }
  };

  onSave = () => {
    const { dispatch, template, sites, detail } = this.props;
    const conf = JSON.parse(
      JSON.stringify(detail.conf || '{}'),
    ) as InspectionTemplateConf;
    let siteCount = 0;
    let itemCount = 0;
    const siteMap = arr2map(sites.result!, x => x.id);

    const clearHash = <T,>(
      obj: { [siteId: number]: T } | undefined,
      scopedSiteIds?: number[],
    ) => {
      if (!obj) return obj;
      const entries = Object.entries(obj).filter(
        x =>
          siteMap[x as any] &&
          (scopedSiteIds == null || scopedSiteIds.includes(x as any)),
      );
      return Object.fromEntries(entries) as typeof obj;
    };

    for (const category of conf.categories) {
      if (category.defaultOrders) {
        category.defaultOrders = clearHash(category.defaultOrders);
      }
      if (category.workflowOrders) {
        category.workflowOrders = clearHash(category.workflowOrders);
      }
      for (const group of category.groups) {
        group.siteIds = group.siteIds.filter(x => siteMap[x]);
        if (group.disabledSiteIds) {
          group.disabledSiteIds = group.disabledSiteIds.filter(
            x => siteMap[x] && group.siteIds.includes(x),
          );
        }
        if (group.defaultHiddenSiteIds) {
          group.defaultHiddenSiteIds = group.defaultHiddenSiteIds.filter(
            x => siteMap[x] && group.siteIds.includes(x),
          );
        }
        group.requiredSiteIds = group.requiredSiteIds?.filter(x =>
          group.siteIds.includes(x),
        );
        if (group.siteRels) {
          group.siteRels = clearHash(group.siteRels, group.siteIds);
        }
        if (group.workflowOrders) {
          group.workflowOrders = clearHash(group.workflowOrders, group.siteIds);
        }
        const disabledSet = new Set(group.disabledSiteIds || []);
        for (const siteId of group.siteIds) {
          if (disabledSet.has(siteId)) continue;
          const site = siteMap[siteId];
          siteCount++;
          itemCount += site.checkItems!.length;
        }
      }
    }
    dispatch(templateDetailActions.save(template!, conf, siteCount, itemCount));
  };

  ready() {
    const { template } = this.props;
    if (!template) return;

    const { dispatch } = this.props;
    try {
      const conf = JSON.parse(template.conf || '{}') as InspectionTemplateConf;
      if (!conf.categories) conf.categories = [];
      for (const category of conf.categories) {
        category.expanded = true;
      }
      dispatch(templateDetailActions.ready(template.id, conf));
    } catch (e) {
      console.error(e);
      dispatch(templateDetailActions.ready(template.id, { categories: [] }));
    }
  }

  getGroup(groupId: string): InspectionTemplateGroup | undefined {
    const detail = this.props.detail;
    if (!detail?.conf) return undefined;
    const categoryId = detail.groupIdToCategoryIdMap.get(groupId)!;
    const category = detail.conf.categories.find(x => x.id === categoryId)!;
    if (!category) return undefined;
    return category.groups.find(x => x.id === groupId)!;
  }

  getCurrentGroup() {
    const groupId = this.props.detail.selectedGroupId!;
    return this.getGroup(groupId);
  }

  buildSiteList() {
    const { sites, detail } = this.props;

    const currentGroup = this.getCurrentGroup();
    if (!currentGroup && !detail.allGroupsSelectedCategoryId) {
      return [];
    }

    const map = arr2map(sites.result!, x => x.id);
    const category = detail.allGroupsSelectedCategoryId
      ? detail.conf!.categories.find(
          x => x.id === detail.allGroupsSelectedCategoryId,
        )!
      : null;

    let list = (
      currentGroup
        ? currentGroup.siteIds.map(x => map[x])
        : category!.groups.reduce((res, g) => {
            return [...res, ...g.siteIds.map(x => map[x])];
          }, [])
    ).filter(isNotNull);

    const keyword = currentGroup
      ? currentGroup.keyword?.trim()
      : category!.keyword?.trim();

    if (keyword) {
      list = list.filter(
        x => x.pyInitial?.includes(keyword) || x.name.includes(keyword),
      );
    }

    list = list.filter(x => x);

    if (currentGroup) {
      if (
        detail.siteListSortType === 'workflow' &&
        currentGroup.workflowOrders
      ) {
        list.sort(
          createOrderComparerFromMap(
            list,
            x => x.id,
            currentGroup.workflowOrders,
          ),
        );
      }
    } else {
      if (detail.allGroupsSiteListSortType === 'default') {
        list.sort(
          createOrderComparerFromMap(list, x => x.id, category!.defaultOrders),
        );
      } else {
        list.sort(
          createOrderComparerFromMap(list, x => x.id, category!.workflowOrders),
        );
      }
    }

    return list;
  }

  calcPanelHeight() {
    if (this.detailViewRef.current) {
      this.detailViewRef.current.style.minHeight = '0px';
    }
    const footerEl = document.querySelector('.m-grid__item.m-footer');
    const contentEl = document.querySelector('.m-subheader + .m-content');
    if (footerEl && contentEl) {
      const footerRect = footerEl.getBoundingClientRect();
      const contentRect = contentEl.getBoundingClientRect();
      this.panelHeight = footerRect.top - contentRect.top - 60 - 2.2 * 13;
      console.log('panel height: %s', this.panelHeight);
    }
  }
}

export const InspectionTemplateDetailManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<Props, InspectionTemplateDetailManagerImpl>(
    withLocalize<Props>(InspectionTemplateDetailManagerImpl) as any,
  ),
);
